import React from "react";
import AboutMe from "./AboutMe";
import AdditionalLinks from "./AdditionalLinks";
import CompanyStages from "./CompanyStages";
import EngagementFlexibility from "./EngagementFlexibility";
import Header from "./Header";
import Industries from "./Industries";
import Projects from "./Projects";
import Skills from "./Skills";
import Tools from "./Tools";
import { Loader } from "../onboard/screens";

const ProfilePage = ({ talentData, setTalentData, talent }) => {
  return (
    <div className="w-full h-full">
      {talentData ? 
        <div className="flex p-4">
          <div className="flex flex-col w-2/3 p-2 gap-4">
            <Header talentData={talentData} />
            <AboutMe talentData={talentData} />
            <Projects talentData={talentData} />
            <Skills talentData={talentData} />
            <Tools talentData={talentData} />
          </div>
          <div className="flex flex-col w-1/3 p-2 gap-4">
            <EngagementFlexibility talentData={talentData} />
            <AdditionalLinks talentData={talentData} />
            <Industries talentData={talentData} />
            <CompanyStages talentData={talentData} />
          </div>
        </div> :
        <Loader bgColor="bg-lightest-grey" />
      }
    </div>
  );
};

export default ProfilePage;

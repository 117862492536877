import React from "react";
import $ from "strings/talent";
import { Label, Sublabel, Textarea } from "components/Form";


const OtherExperience = ({
  values,
  setFieldValue
}) => {
  return (
    <div className="px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.other_experience_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.niche_subtitle}</div>
      </div>
      <div className="flex flex-col gap-1">
        <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.other_experience_label}</Label>
        <Sublabel>{$.other_experience_sublabel}</Sublabel>
        <Textarea
          placeholder={$.textarea_placeholder} value={values.qualificationsOther} onChange={(e) => setFieldValue('qualificationsOther', e.target.value)}
        />
      </div>
    </div>
  )
};

export default OtherExperience;


import React from "react";
import clsx from "clsx";

const RatingHelper = ({ label, showDisplayOnProfile }) => {
  return (
    <div className="flex text-mini text-kasmir">
      <div className="flex-1">
        {label} name
      </div>
      <div className={clsx("flex gap-2", showDisplayOnProfile ? "flex-1 ml-24" : "ml-auto mr-2")}>
        <span>Beginner</span>
        <span>Intermediate</span>
        <span>Expert</span>
      </div>
      {showDisplayOnProfile && 
        <div className="visible ml-auto mr-2 flex gap-2">
          <span>Display on profile page</span>
        </div>
      }
    </div>
  )
};

export default RatingHelper;
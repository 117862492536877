import React from "react";
import $ from "strings/talent";
import { SPECIALTY_OPTIONS } from "../onboard/constants";
import { ExperienceIcon, LocationIcon, SpecialtiesIcon } from "pages/talent/onboard/icons";
import userImg from "assets/user.svg";

const Header = ({ talentData, setTalentData }) => {
  const { user, specialties, years_of_experience, currently_available } = talentData;

  return (
    <div className="bg-white rounded-b-lg">
      <div className="flex-1 h-24 bg-alice-blue rounded-t-lg" />
      <div className="flex-1 bg-white p-2 rounded-b-lg">
        <div className="flex flex-col relative">
          <div className="absolute bottom-0 rounded-xl" style={{ height: "180px", width: "180px" }}>
            <img src={userImg} />
          </div>
          <div style={{ marginLeft: "200px" }}>
            <div className="flex gap-4 py-1 items-center">
              <h1 className="text-xl font-bold">{user.first_name} {user.last_name}</h1>
              <div className="text-medium pt-1">({user.pronouns})</div>
              {!currently_available &&
                <div className="bg-muted-green text-medium-green py-1 px-2 rounded-lg text-sm">
                  {$.currently_available}
                </div>
              }
            </div>
            <div className="flex gap-2 py-1">
              <LocationIcon />
              <div className="text-sm text-dark-navy">{$.location_header_label}</div>
              <div className="text-sm text-kasmir">Somewhere</div>{/* TODO add location to endpoint? */}
            </div>
            <div className="flex gap-2 py-1">
              <ExperienceIcon />
              <div className="text-sm text-dark-navy">{$.experience_header_label}</div>
              <div className="text-sm text-kasmir">{years_of_experience} {years_of_experience === 1 ? 'year' : 'years'}</div>
            </div>
            <div className="flex gap-2 py-1 items-center">
              <SpecialtiesIcon />
              <div className="text-sm text-dark-navy">{$.specialties_header_label}</div>
              {specialties && specialties.split("|").map(s =>
                <div className="bg-lightest-grey py-1 px-2 rounded-lg text-sm text-dark-navy">
                  {SPECIALTY_OPTIONS.find(o => o.value === s)?.label}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Header;

import React from "react";
import LoaderJson from "../../assets/loader_generic.json";
import Lottie from "react-lottie"; 
import $ from "strings/talent";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loader = ({ msg, bgColor }) => {
  return (
    <div className={"loader font-lato px-8 py-4 w-full h-full flex flex-col justify-center items-center text-center " + bgColor}>
      <div className="w-full sm:w-1/2 items-center justify-center">
        <Lottie options={defaultOptions} />
      </div>
      <div className="w-full sm:w-1/2 items-center justify-center">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">
          {msg}
        </div>
        {msg &&
        <div className="text-midnight text-base sm:text-lg font-normal">
          {$.loading_subtitle}
        </div>
        }
      </div>
    </div>
  ) 
};

export default Loader;
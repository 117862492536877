import React from "react";
import $ from "strings/talent";
import { Button } from "components/Buttons";

const ActionBar = ({ onClickCancel, onClickSave, isUpdating, displayActionBar }) => {
  return (
    <>
      {displayActionBar ?
        <div className="mt-4 mb-2 flex gap-2">
          <Button className="cancelBtn flex text-electric-indigo bg-alice-blue text-sm sm:text-base rounded" onClick={onClickCancel}>
            <span>{$.cancel_btn}</span>
          </Button>
          <Button disabled={isUpdating} className="animatedBtn flex bg-electric-indigo text-sm sm:text-base rounded" onClick={onClickSave}>
            <span>{$.save_btn}</span>
          </Button>
        </div> :
        <div style={{ height: '64px' }} />
      }
    </>
  )
};

export default ActionBar;

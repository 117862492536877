import React, { useMemo, useState, useEffect } from "react";
import $ from "strings/talent";
import $$ from "strings/talent";
import { Label } from "components/Form";
import {
  RatingHelper,
  Qualification
} from "../components"
import ActionBar from "./ActionBar";
import { remove } from "lodash";

const HIDDEN_INDUSTRY_IDS = [
  '57ddc7ac-da7e-4eee-946c-0b697e0819e8' // International Talent - not user facing  
];

const Industries = ({
  rsuQualifications,
  talentIndustries,
  onUpdate,
  isUpdating
})=> {
  const [talentValues, setTalentValues] = useState(talentIndustries);
  const [displayActionBar, setDisplayActionBar] = useState(false);
  const [toUpdate, setToUpdate] = useState([]);
  const [toCreate, setToCreate] = useState([]);

  useEffect(() => {
    if (!toCreate || !toUpdate) setDisplayActionBar(false);
    toUpdate.length || toCreate.length ? setDisplayActionBar(true) : setDisplayActionBar(false)
  }, [toCreate, toUpdate]);

  const [ratedIndustries, RSUIndustries] = useMemo(() => {
    let talent = [];
    let rsu = [];
    
    const industries = rsuQualifications?.filter(q => HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 && q.type.toLowerCase() === 'industry');
    industries.forEach(s => { 
      const isRated = talentIndustries.find(ts => ts.qualification_id === s.id);
      isRated ? talent.push({...s, talent_rating: isRated.talent_rating}) : rsu.push(s)
    });
    talent.sort((a, b) => b.talent_rating - a.talent_rating);
    rsu.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    
    return [talent, rsu];
  }, [rsuQualifications])
  
  // INFO: industries are qualifications
  const onRatingChange = (id, rating) => {
    let newToUpdate = toUpdate;
    let newToCreate = toCreate;
    let newTalentValues = talentValues;
    let checked = document.getElementById(`checkbox--${id}`)?.checked;
    const existingQualification = talentIndustries.find(industry => industry.qualification_id === id);
    
    if (existingQualification) {
      remove(newToUpdate, industry => industry.qualification_id === id);
      newToUpdate.push({
        qualification_id: id,
        talent_qualification_id: existingQualification.talent_qualification_id,
        talent_rating: rating,
        display_on_talent_profile: checked
      });
      setToUpdate([...newToUpdate]);
    } else {
      remove(newToCreate, industry => industry.qualification_id === id);
      if (rating !== 0) {
        newToCreate.push({
          qualification_id: id,
          talent_rating: rating,
          display_on_talent_profile: checked,
        });
        setToCreate([...newToCreate]);
      }
    }
    remove(newTalentValues, skill => skill.qualification_id === id);
    newTalentValues.push({qualification_id: id, talent_rating: rating, display_on_talent_profile: checked});
    setTalentValues([...newTalentValues]);
  };

  const onCheckboxClick = (id) => {
    const value = document.getElementById(`checkbox--${id}`)?.checked;
    let newToUpdate = toUpdate;
    let newTalentValues = talentValues;
    const existingQualification = talentIndustries.find(industry => industry.qualification_id === id);
    if (!existingQualification) return;
    remove(newToUpdate, industry => industry.qualification_id === id);
    let newVal = {
      qualification_id: existingQualification.qualification_id,
      qualification_name: existingQualification.qualification_name,
      talent_qualification_id: existingQualification.talent_qualification_id,
      talent_rating: existingQualification.talent_rating,
      display_on_talent_profile: value
    }
    if (existingQualification.display_on_talent_profile !== value) {
      newToUpdate.push(newVal);
      setToUpdate([...newToUpdate]);
    }
    remove(newTalentValues, industry => industry.qualification_id === id);
    newTalentValues.push(newVal);
    setTalentValues([...newTalentValues]);
  }

  const onClickSave = () => {
    const response = onUpdate(toCreate, toUpdate);
    if (response) {
      setToUpdate([])
      setToCreate([])
      setDisplayActionBar(false)
    }
  }

  const onClickCancel = () => {
    setToUpdate([])
    setToCreate([])
    setTalentValues(talentIndustries)
    setDisplayActionBar(false)
  }

  return (
    <div className="bg-white rounded-xl px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl font-bold">{$.industries_title}</div>
      </div>
      <div className="flex flex-col">
        <div className="pb-2">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$$.industries_helper}</Label>
        </div>
        <div className="ratingContainer industryContainer bg-lightest-grey rounded-xl p-2 sm:px-4 overflow-y-scroll">
          <div className="flex flex-col py-2 gap-2">
            <RatingHelper label={'Industry'} showDisplayOnProfile />
            {ratedIndustries.map(q =>
              <Qualification
                key={q.id}
                qualification={q}
                rating={talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                onRatingChange={onRatingChange}
                showCheckbox
                disabled={isUpdating}
                checkboxDisabled={isUpdating || !talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0}
                onCheckboxClick={() => onCheckboxClick(q.id)}
                checked={talentValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
              />
            )}
            {RSUIndustries.map(q =>
              <Qualification
                key={q.id}
                qualification={q}
                rating={talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                onRatingChange={onRatingChange}
                showCheckbox
                disabled={isUpdating}
                checkboxDisabled={isUpdating || !talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0}
                onCheckboxClick={() => onCheckboxClick(q.id)}
                checked={talentValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
              />
            )}
          </div>
        </div>
      </div>
      <ActionBar displayActionBar={displayActionBar} isUpdating={isUpdating} onClickCancel={onClickCancel} onClickSave={onClickSave} />
    </div>
  )
};

export default Industries;

import React, { useState, useEffect, useMemo } from "react";
import clsx from 'clsx';
import toast, { Toaster } from 'react-hot-toast';
import $ from "strings/talent";
import {
  IndustriesIcon,
  SpecialtiesIcon,
  SkillsIcon,
  ToolsIcon
} from "pages/talent/onboard/icons";
import Toast from "../components/Toast";
import { Loader } from "../onboard/screens";
import Specialties from "./Specialties";
import Skills from "./Skills";
import Tools from "./Tools";
import Industries from "./Industries";
import {
  getQualifications,
  patchTalentTalent,
  postTalentQualification,
  patchTalentTalentQualification
} from "utils/api";

const ASSESSMENTS_SCREENS = {
  "specialties": {
    icon: <SpecialtiesIcon />,
    activeIcon: <SpecialtiesIcon active={true} />,
    title: $.specialties_title,
    component: <Specialties />
  },
  "skills": {
    icon: <SkillsIcon />,
    activeIcon: <SkillsIcon active={true} />,
    title: $.skills_title
  },
  "tools": {
    icon: <ToolsIcon />,
    activeIcon: <ToolsIcon active={true} />,
    title: $.tools_title
  },
  "industries": {
    icon: <IndustriesIcon />,
    activeIcon: <IndustriesIcon active={true} />,
    title: $.industries_title
  }
}

const AssessmentsPage = ({ talentData, setTalentData, talent, activeView }) => {
  const [rsuQualifications, setRsuQualifications] = useState();
  const [activeScreen, setActiveScreen] = useState(activeView || 'specialties');
  const [isUpdating, setIsUpdating] = useState(false);

  const talentQualifications = useMemo(() => {
    if (!talentData || !talentData.qualifications) return;
    const { qualifications } = talentData;
    let skills;
    let tools;
    let industries;

    industries = qualifications
                  .filter(q => q.qualification?.type?.toLowerCase() === 'industry')
                  .map(industry => ({
                    'qualification_id': industry.qualification.id,
                    'qualification_name': industry.qualification.name,
                    'talent_qualification_id': industry.id,
                    'talent_rating': industry.talent_rating,
                    'display_on_talent_profile': industry.display_on_talent_profile
                  }));
    skills = qualifications
                  .filter(q => q.qualification?.type?.toLowerCase() === 'skill' && q.talent_rating !== 0)
                  .map(skill => ({
                    'qualification_id': skill.qualification.id,
                    'qualification_name': skill.qualification.name,
                    'talent_qualification_id': skill.id,
                    'talent_rating': skill.talent_rating,
                    'display_on_talent_profile': skill.display_on_talent_profile
                  }));
    tools = qualifications
                  .filter(q => q.qualification?.type?.toLowerCase() === 'tool' && q.talent_rating !== 0)
                  .map(tool => ({
                    'qualification_id': tool.qualification.id,
                    'qualification_name': tool.qualification.name,
                    'talent_qualification_id': tool.id,
                    'talent_rating': tool.talent_rating,
                    'display_on_talent_profile': tool.display_on_talent_profile
                  }));
    return {skills, tools, industries};
  }, [talentData]);

  const fetchQualifications = async () => {
    try {
      const qualificationsResponse = await getQualifications({
        page_size: 9999
      });
      setRsuQualifications(qualificationsResponse.results);
    } catch (e) {
      console.error('Failed to fetch qualifications')
    }
  }

  const onNavClick = (screen) => {
    setActiveScreen(screen)
  };

  useEffect(() => {
    fetchQualifications();
  }, []);

  const onUpdateTalent = async (toSend) => {
    setIsUpdating(true);
    const originalValues = { ...talentData };
    try {
      const response = await patchTalentTalent(talent, toSend);
      setTalentData({ ...talentData, ...toSend })
      setIsUpdating(false);
      toast(<Toast message='Specialties saved successfully' />);
      return response;
    } catch (e) {
      setTalentData({ ...originalValues })
      setIsUpdating(false);
      console.error({e})
    }
  }

  const onUpdateQualifications = async (toCreate, toUpdate) => {
    setIsUpdating(true);
    const originalValues = [ ...talentData.qualifications ];
    try {
      toCreate.forEach(q => {
        const rsuQualification = rsuQualifications.find(qual => qual.id === q.qualification_id);
        if (!rsuQualification) return;
        const toSend = {
          user: talent,
          qualification: rsuQualification.id,
          talent_rating: q.talent_rating,
          display_on_talent_profile: q.display_on_talent_profile
        }
        postTalentQualification(toSend);
      });
      toUpdate.forEach(q =>
        patchTalentTalentQualification(q.talent_qualification_id, {
          talent_rating: q.talent_rating,
          display_on_talent_profile: q.display_on_talent_profile
        })
      );
      setIsUpdating(false);
      toast(<Toast message='Qualifications saved successfully' />, {timeout: 2000});
      return true;
    } catch (e) {
      setTalentData({ ...talentData, qualifications: [...originalValues ] })
      setIsUpdating(false);
      console.error({e})
    }
  }

  const NavItem = ({ name, onNavClick }) => {
    return (
      <div className={clsx("flex flex-col justify-center w-full sm:px-4 sm:py-2 ", activeScreen === name ? "flex-3 sm:flex-1" : "flex-1")}>
        <div
          className={clsx("flex cursor-pointer items-center", (activeScreen === name) && "sm:bg-lightest-grey sm:rounded-lg ", activeScreen === name && " text-electric-indigo")}
          onClick={() => onNavClick(name)}
        >
          <div className="h-full bg-lightest-grey sm:bg-transparent rounded-lg p-2">
            {activeScreen === name ? ASSESSMENTS_SCREENS[name].activeIcon : ASSESSMENTS_SCREENS[name].icon}
          </div>
          <div className={clsx("ml-2 text-sm sm:text-base font-semibold py-2", activeScreen === name ? "text-electric-indigo " : "hidden sm:flex sm:text-kasmir")}>
            {ASSESSMENTS_SCREENS[name].title}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full h-full">
      {talentData && rsuQualifications ?
        <div className="flex">
          <div className="w-1/3 p-4">
            <div className="bg-white rounded-xl p-2">
              <div className="text-xl m-2">{$.niche_navlink}</div>
              {Object.keys(ASSESSMENTS_SCREENS).map(screen =>
                <NavItem key={screen} name={screen} onNavClick={onNavClick} />
              )}
            </div>
          </div>
          <div className="w-2/3 p-4">
            {{
              'specialties': <Specialties talentData={talentData} setTalentData={setTalentData} onUpdate={onUpdateTalent} isUpdating={isUpdating} />,
              'skills': <Skills rsuQualifications={rsuQualifications} talentSkills={talentQualifications?.skills} talentData={talentData} onUpdate={onUpdateQualifications} isUpdating={isUpdating} />,
              'tools': <Tools rsuQualifications={rsuQualifications} talentTools={talentQualifications?.tools} talentData={talentData} onUpdate={onUpdateQualifications} isUpdating={isUpdating} />,
              'industries': <Industries rsuQualifications={rsuQualifications} talentIndustries={talentQualifications?.industries} talentData={talentData} onUpdate={onUpdateQualifications} isUpdating={isUpdating} />,
            }[activeScreen] || <div />}
          </div>
        </div> :
        <Loader />
      }
      <Toaster
        toastOptions={{
          style: {
            border: '1px solid ##e5f2ea',
            borderRadius: '5px',
            backgroundColor: '#e5f2ea',
          },
        }}
      />
    </div>
  );
};

export default AssessmentsPage;

import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import $ from "strings/talent";
import $$ from "strings/talent";
import {NicheIcon} from "pages/talent/onboard/icons";
import {Checkbox, Dropdown, Input, PhoneInput} from "components/Form";
import {
  getTalentDataFromUrlSuffix,
  patchTalentTalent,
  patchTalentUser,
  patchTalentUserContactInfo,
  patchTalentUserLinks,
  postTalentUserLinks
} from "utils/api";

const SETTINGS_SCREENS = {
  "contact": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.contact_title
  },
  "credentials": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.credentials_title
  },
  "profile_settings": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.profile_settings_title,
    subsections: {
      "employment": {
        title: $$.employment_status_title,
      },
      "opportunity": {
        title: $$.opportunity_preferences_title,
      },
      "availability": {
        title: $$.availability_title,
      },
      "experience": {
        title: $$.experience_title,
      }
    }
  }
};

const PRONOUNS_OPTIONS = [
  { value: '', label: 'Select pronouns' },
  { value: 'he/him', label: 'He/Him' },
  { value: 'she/her', label: 'She/Her' },
  { value: 'they/them', label: 'They/Them' },
  { value: 'other', label: 'Other' }
];

const EMPTY_SCHEDULING_LINK = {id: '', url: '', name: 'Scheduling Link'};

const SettingsPage = ({talentData, setTalentData}) => {
  const [activeScreen, setActiveScreen] = useState('contact');
  const [activeSubsection, setActiveSubsection] = useState('employment');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [preferredName, setPreferredName] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [schedulingLink, setSchedulingLink] = useState(EMPTY_SCHEDULING_LINK);
  const [commEmail, setCommEmail] = useState(false);
  const [commText, setCommText] = useState(false);
  const [commPhone, setCommPhone] = useState(false);

  const getSchedulingLink = (td) => {
    for (const link of td.user.user_links) {
      if (link.name === EMPTY_SCHEDULING_LINK.name) {
        return link;
      }
    }
    return EMPTY_SCHEDULING_LINK;
  };

  useEffect(() => {
    if (talentData) {
      setFirstName(talentData.user.first_name || '');
      setLastName(talentData.user.last_name || '');
      setPreferredName(talentData.user.preferred_name || '');
      setPronouns(talentData.user.pronouns || '');
      setPhone(talentData.user.phone || '');
      setLocation(talentData.user.location || '');
      setSchedulingLink(getSchedulingLink(talentData));
      const cp = talentData.communication_preferences || '';
      setCommEmail(cp.includes('email'));
      setCommText(cp.includes('text'));
      setCommPhone(cp.includes('phone'));
      setPhone(talentData.user.user_contact_info.phone_number || '');
      setLocation(talentData.user.user_contact_info.full_address || '');
    }
  }, [talentData]);

  const patch = async (model_name='talent', field_name, value) => {
    let oldValue;
    if (model_name === 'talent') {
      oldValue = talentData[field_name];
    } else if (model_name === 'scheduling_link') {
      oldValue = getSchedulingLink(talentData)[field_name];
    } else if (model_name === 'user_contact_info') {
      oldValue = talentData.user.user_contact_info[field_name];
    } else {
      oldValue = talentData[model_name][field_name];
    }
    if (oldValue === value) return;
    const data = { [field_name]: value };
    if (model_name === 'talent') {
      if (field_name === 'communication_preferences') {
        data[field_name] = value || null;
      }
      await patchTalentTalent(talentData.user.id, data);
    } else if (model_name === 'user') {
      if (field_name === 'pronouns') {
        data[field_name] = value || null;
      }
      await patchTalentUser(talentData.user.id, data);
    } else if (model_name === 'scheduling_link') {
      if (schedulingLink.id) {
        await patchTalentUserLinks(schedulingLink.id, data);
      } else {
        data['name'] = EMPTY_SCHEDULING_LINK.name;
        await postTalentUserLinks(data);
      }
    } else if (model_name === 'user_contact_info') {
      await patchTalentUserContactInfo(talentData.user.id, data);
    } else {
      console.error('Unknown model_name', model_name);
    }
    const dt = await getTalentDataFromUrlSuffix(talentData.url_suffix);
    setTalentData(dt.data);
  };

  const getNewPrefs = pref => {
    const oldPrefs = talentData.communication_preferences || '';
    const oldPrefsArray = oldPrefs.split('|');
    if (oldPrefs === '') {
      return pref;
    } else if (oldPrefsArray.includes(pref)) {
      const newPrefsArray = oldPrefsArray.filter(p => p !== pref);
      return newPrefsArray.join('|');
    } else {
      return oldPrefs + `|${pref}`;
    }
  };

  const onNavClick = (screen) => {
    if (activeScreen === 'profile_settings' && screen !== 'profile_settings') {
      setActiveSubsection('employment')
    }
    setActiveScreen(screen)
  };

  const getPageSubtitle = () => {
    if (SETTINGS_SCREENS[activeScreen].subsections) {
      return SETTINGS_SCREENS[activeScreen].subsections[activeSubsection].title
    } else {
      return SETTINGS_SCREENS[activeScreen].title
    }
  }

  const onSubsectionClick = (subsection) => {
    setActiveSubsection(subsection)
  };

  const NavItem = ({name, onNavClick}) => {
    return (
      <div
        className={clsx("flex flex-col justify-center w-full sm:py-2 ", activeScreen === name ? "flex-3 sm:flex-1" : "flex-1")}>
        <div
          className={clsx("flex cursor-pointer items-center p-3", (activeScreen === name && !SETTINGS_SCREENS[name].subsections) && "sm:bg-lightest-grey sm:rounded-lg ", (activeScreen === name) && " text-electric-indigo")}
          onClick={() => onNavClick(name)}
        >
          <div className="h-full bg-lightest-grey sm:bg-transparent rounded-lg pr-2">
            {activeScreen === name ? SETTINGS_SCREENS[name].activeIcon : SETTINGS_SCREENS[name].icon}
          </div>
          <div className={clsx("ml-2 text-sm sm:text-base font-semibold", activeScreen === name ? "text-electric-indigo " : "hidden sm:flex sm:text-kasmir")}>
            {SETTINGS_SCREENS[name].title}
          </div>
        </div>
        {(activeScreen === name && SETTINGS_SCREENS[name].subsections) &&
          <div className="flex flex-col my-2">
            {Object.keys(SETTINGS_SCREENS[name].subsections).map(subsection =>
              <div
                key={subsection}
                className="cursor-pointer"
                onClick={() => onSubsectionClick(subsection)}
              >
                <div className={
                  clsx("flex ml-8 py-2 font-medium text-sm text-kasmir ",
                  activeSubsection === subsection && "bg-lightest-grey rounded-lg text-electric-indigo")
                }>
                  <div className="pl-2">{SETTINGS_SCREENS[name].subsections[subsection].title}</div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    )
  };

  const Header = ({title, className}) => {
    return (
      <div
        className={`text-2xl font-bold ${className}`}
      >{title}</div>
    )
  };

  const Label = ({label, helper}) => {
    return (<>
      {label && (
          <h2 className={clsx("text-lg font-bold", !helper && "mb-2")}>
            {label}
          </h2>
        )}
      {helper && <div className="text-gray-500 mb-1">{helper}</div>}
    </>)
  };

  return (
    <div className="flex p-4">
      <div className="w-1/4">
        <div className="bg-white rounded-xl p-4">
          <Header className="py-3" title={$.settings_navlink} />
          {Object.keys(SETTINGS_SCREENS).map(screen =>
            <NavItem key={screen} name={screen} onNavClick={onNavClick} />
          )}
        </div>
      </div>
      <div className="w-3/4 pl-4">
        <div className="bg-white rounded-xl p-4">
          <Header className="mt-3 mb-8" title={getPageSubtitle()} />
          <div className="w-full">
            {activeScreen === 'contact' && (<>
              <div className="flex flex-row mb-6">
                <div className="flex-col w-1/2 mr-4">
                  <Label label={$.first_name_label} />
                  <Input
                    type="text"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    onBlur={() => patch('user', 'first_name', firstName)}
                  />
                </div>
                <div className="flex-col w-1/2">
                  <Label label={$.last_name_label} />
                  <Input
                    type="text"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    onBlur={() => patch('user', 'last_name', lastName)}
                  />
                </div>
              </div>
              <div className="flex flex-row mb-6">
                <div className="flex-col w-1/2 mr-4">
                  <Label
                    label={$.preferred_first_name_label}
                    helper={$.preferred_first_name_helper}
                  />
                  <Input
                    type="text"
                    value={preferredName}
                    onChange={e => setPreferredName(e.target.value)}
                    onBlur={() => patch('user', 'preferred_name', preferredName)}
                  />
                </div>
                <div className="flex-col w-1/2">
                  <Label
                    label={$.preferred_pronouns_label}
                    helper={$.preferred_pronouns_helper}
                  />
                  <Dropdown
                    options={PRONOUNS_OPTIONS}
                    value={pronouns}
                    onChange={async e => {
                      setPronouns(e.target.value);
                      await patch('user', 'pronouns', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row mb-6">
                <div className="flex-col w-1/2 mr-4">
                  <Label label={$.phone_label} />
                  <PhoneInput
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    onBlur={() => patch('user_contact_info', 'phone_number', phone)}
                  />
                </div>
                <div className="flex-col w-1/2">
                  <Label label={$.location_label} />
                  <Input
                    type="text"
                    value={location}
                    onChange={e => setLocation(e.target.value)}
                    onBlur={() => patch('user_contact_info', 'full_address', location)}
                  />
                </div>
              </div>
              <div className="flex flex-row mb-6">
                <div className="flex-col w-1/2 mr-4">
                  <Label
                    label={$.scheduling_link_label}
                    helper={$.scheduling_link_helper}
                  />
                  <Input
                    type="text"
                    value={schedulingLink.url}
                    onChange={e => setSchedulingLink({...schedulingLink, url: e.target.value})}
                    onBlur={() => patch('scheduling_link', 'url', schedulingLink.url)}
                  />
                </div>
              </div>
              <div className="flex flex-row mb-6">
                <div className="flex-col w-1/2 mr-4">
                  <h2 className="text-lg font-bold mb-2">
                    {$.preferred_communication_method_label}
                  </h2>
                  <div className="flex mb-2">
                    <Checkbox
                      value={commEmail}
                      onChange={async () => {
                        setCommEmail(!commEmail);
                        await patch('talent', 'communication_preferences', getNewPrefs('email'));
                      }}
                      outlined
                    />
                    <div className="ml-2">{$.preferred_communication_email_label}</div>
                  </div>
                  <div className="flex mb-2">
                    <Checkbox
                      value={commText}
                      onChange={async () => {
                        setCommText(!commText);
                        await patch('talent', 'communication_preferences', getNewPrefs('text'));
                      }}
                      outlined
                    />
                    <div className="ml-2">{$.preferred_communication_sms_label}</div>
                  </div>
                  <div className="flex">
                    <Checkbox
                      value={commPhone}
                      onChange={async () => {
                        setCommPhone(!commPhone);
                        await patch('talent', 'communication_preferences', getNewPrefs('phone'));
                      }}
                      outlined
                    />
                    <div className="ml-2">{$.preferred_communication_phone_label}</div>
                  </div>
                </div>
              </div>
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;

import React, { useMemo } from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";
import Pill from "../components/Pill";

const Skills = ({ talentData }) => {
  const { qualifications } = talentData;

  const skills = useMemo(() => {
    return qualifications.filter(q => q.qualification?.type.toLowerCase() === 'skill' && q.display_on_talent_profile === true);
  }, [talentData]);

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex gap-2 items-center">
        <div className="text-normal">Skills</div>
        <Pill value={skills.length} />
        <div className="ml-auto mr-0">
          <EditBtn />
        </div>
      </div>
      <div className="py-2 text-sm flex gap-2">
        {skills.map((s, i) =>
          <div className="inline-flex bg-lightest-grey px-3 py-2 rounded-lg text-sm text-dark-navy" key={i}>
            {s.qualification?.name}
          </div>
        )}
      </div>
    </div>
  )
};

export default Skills;

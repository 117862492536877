import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import {GoogleLogin} from '@react-oauth/google';
import {oauth} from "utils/adminApi";
import {loginPassword} from "utils/api";
import logoSm from "assets/logo_sm.svg";
import {setToken, setUrlSuffix, setUserId} from "utils/localStorageService";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Input} from "components/Form";
import {Button} from "components/Buttons";
import {useHistory} from "react-router-dom";

const Login = ({ setLogged }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  useEffect(() => {
    document.title = "Login";
  }, []);

  const history = useHistory();

  const alert = useAlert();
  const responseGoogle = async (response) => {
    try {
      const authResponse = await oauth(response.credential);
      const talent = authResponse.talent;
      if (!talent) {
        alert.error(<AlertError message="Talent not found." />);
        return;
      }
      setToken(authResponse);
      setUserId(authResponse.talent.user.id);
      const suffix = authResponse.talent.url_suffix
      setUrlSuffix(suffix);
      setLogged(true);
      history.push(`/talent/${suffix}`);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  const failedGoogle = (response) => {
    console.error(response);
    const e = { message: response.error };
    alert.error(<AlertError error={e} message={$.google_auth_error} />);
  };

  const handleLogin = async () => {
    try {
      const response = await loginPassword({ email, password });
      const talent = response.talent;
      if (!talent) {
        alert.error(<AlertError message="Talent not found." />);
        return;
      }
      setToken(response);
      setUserId(response.talent.user.id);
      const suffix = response.talent.url_suffix
      setUrlSuffix(suffix);
      setLogged(true);
      history.push(`/talent/${suffix}`);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center px-24 py-12">
      <div className="rounded-2xl bg-white relative w-full h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col items-center justify-center px-10"
          >
            <h1
              className="font-semibold text-2xl">
              Welcome back to Right Side Up!
            </h1>
            <div className="flex justify-center my-5 w-full">
              <GoogleLogin
                className="w-full"
                onSuccess={responseGoogle}
                onError={failedGoogle}
                context="signin"
                size="large"
              />
            </div>
            <div className="flex items-center w-full mb-4">
              <hr className="flex-auto w-auto border-t text-gray-300"/>
              <span
                className="flex-none mx-4 text-gray-300 text-sm font-normal">OR</span>
              <hr className="flex-auto w-auto border-t text-gray-300"/>
            </div>
            <label
              className="text-sm text-left w-full mb-1">
              Email
            </label>
            <div className="w-full mb-4">
              <Input
                type="email"
                fontSize="text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <label
              className="text-sm text-left w-full mb-1">
              Password
            </label>
            <div className="w-full">
              <Input
                type="password"
                fontSize="text-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <p className="my-3 text-sm text-right w-full text-kasmir">
              <a
                href="/talent/reset-password"
                className="underline text-link hover:text-link-dark"
              >Forgot your password?</a>
            </p>
            <Button
              className="w-full"
              onClick={handleLogin}
            >Log in</Button>
            <p className="mb-4 mt-4 text-sm text-center w-full text-kasmir">
              New to RightSideUp? {" "}
              <a
                href="mailto:talent@rightsideup.co"
                className="underline text-link hover:text-link-dark"
              >Contact our Talent Team!</a>
            </p>
          </div>
          <div
            className="w-1/2 h-full bg-lightest-grey rounded-2xl pt-10 text-center flex flex-col justify-between"
          >
            <div className="px-8 h-1/4">
              <h1 className="text-3xl">
                Be your own boss, boss
              </h1>
              <p className="text-sm mt-2">
                Deliver excellent work that thrills clients by taking on
                projects
                that light you up. Whether you want to moonlight or freelance
                full
                time, you dictate who you work with, how many hours you work,
                and how much you get.
              </p>
            </div>
            <div className="flex-auto w-full flex flex-row text-white text-xl">
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-1/2 bg-link"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-20 bg-link-dark pt-6">RIGHT</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-32 bg-indigo pt-6">SIDE</div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-44 bg-link-dark pt-6">UP</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-52 bg-indigo"></div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

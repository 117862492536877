import React from "react";

const LocationIcon = () => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.64565 18.4269L7.64908 18.428C7.87422 18.519 7.99994 18.4979 7.99994 18.4979C7.99994 18.4979 8.12565 18.519 8.35194 18.428L8.35423 18.4269L8.36108 18.4238L8.38165 18.4153C8.49014 18.3686 8.59723 18.3192 8.7028 18.2671C8.91537 18.1654 9.21251 18.013 9.56794 17.8087C10.2765 17.4021 11.2194 16.7859 12.1668 15.9401C14.0594 14.2504 15.9999 11.6089 15.9999 7.91089C15.9999 6.93767 15.793 5.97399 15.391 5.07486C14.9889 4.17573 14.3997 3.35876 13.6568 2.6706C12.9139 1.98243 12.032 1.43655 11.0614 1.06412C10.0908 0.691688 9.05051 0.5 7.99994 0.5C6.94936 0.5 5.90908 0.691688 4.93847 1.06412C3.96787 1.43655 3.08595 1.98243 2.34308 2.6706C1.60022 3.35876 1.01094 4.17573 0.608903 5.07486C0.206865 5.97399 -6.10508e-05 6.93767 -6.10352e-05 7.91089C-6.10352e-05 11.6079 1.94051 14.2504 3.83422 15.9401C4.62708 16.6455 5.49777 17.2718 6.43194 17.8087C6.79376 18.0168 7.16598 18.209 7.54737 18.3846L7.61823 18.4153L7.6388 18.4238L7.64565 18.4269ZM7.99994 10.293C8.68192 10.293 9.33598 10.042 9.81821 9.59526C10.3005 9.14854 10.5714 8.54265 10.5714 7.91089C10.5714 7.27912 10.3005 6.67323 9.81821 6.22651C9.33598 5.77978 8.68192 5.52882 7.99994 5.52882C7.31795 5.52882 6.6639 5.77978 6.18166 6.22651C5.69943 6.67323 5.42851 7.27912 5.42851 7.91089C5.42851 8.54265 5.69943 9.14854 6.18166 9.59526C6.6639 10.042 7.31795 10.293 7.99994 10.293Z" fill="#0E1539"/>
    </svg>
)
};

export default LocationIcon;

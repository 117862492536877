import React from "react";

const SkillsIcon = ({ active }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.14903 15.7518C3.14903 15.7518 1.9734 14.8124 1.32147 14.2921C0.920966 13.9715 0.687528 13.4866 0.687528 12.9736C0.687528 11.4352 0.687528 6.98974 0.687528 4.5243C0.687528 3.2688 1.52003 2.16518 2.72715 1.82037C4.51365 1.30962 7.27722 0.520432 8.43315 0.189682C8.94109 0.0445574 9.48728 0.145808 9.90972 0.462495C10.5656 0.954683 11.7125 1.81474 11.7125 1.81474C11.9612 2.00093 12.0112 2.35362 11.825 2.60225C11.6388 2.85087 11.2862 2.90093 11.0375 2.71474L9.23472 1.36249C9.09353 1.25674 8.91184 1.22299 8.74253 1.27136L3.03653 2.90205C2.31203 3.10905 1.81253 3.77112 1.81253 4.5243V12.9736C1.81253 13.1446 1.89015 13.3061 2.02403 13.4129C2.6754 13.9338 3.85103 14.8732 3.85103 14.8732C4.09347 15.0667 4.1334 15.4211 3.93934 15.6635C3.74584 15.9059 3.39147 15.9459 3.14903 15.7518Z" fill={active ? "#3049C5" : "#16325C"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7479 9.1719L10.207 10.4451L8.8911 10.587C8.50289 10.6289 8.16972 10.8997 8.04909 11.2873C7.93229 11.6629 8.04191 12.074 8.32386 12.3377L9.32432 13.2748L9.04093 14.6529C8.96147 15.0405 9.11274 15.4386 9.42005 15.6719C9.74317 15.9166 10.1745 15.9391 10.5206 15.7303L11.6594 15.0415L12.7981 15.7303C13.1442 15.9391 13.5755 15.9166 13.8987 15.6719C14.206 15.4386 14.3572 15.0405 14.2778 14.6529L13.9944 13.2748L14.9948 12.3377C15.2768 12.074 15.3864 11.6629 15.2696 11.2873C15.149 10.8997 14.8158 10.6289 14.4276 10.587L13.1117 10.4451L12.5708 9.1719C12.4118 8.79728 12.0519 8.56201 11.6594 8.56201C11.2668 8.56201 10.9069 8.79728 10.7479 9.1719Z" fill={active ? "#3049C5" : "#16325C"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.90381 14.6392L3.65468 15.8536C3.48481 15.902 3.302 15.8677 3.16137 15.7614C3.02018 15.6551 2.9375 15.4891 2.9375 15.3125V5.47382C2.9375 4.72007 3.437 4.058 4.1615 3.851C6.17525 3.27557 11.2203 1.83388 11.2203 1.83388C11.3902 1.7855 11.573 1.81982 11.7136 1.92613C11.8548 2.03244 11.9375 2.19838 11.9375 2.37501V7.45607C11.8447 7.4437 11.7507 7.43751 11.6562 7.43751C10.8193 7.43751 10.0486 7.93419 9.70944 8.73294L9.4265 9.39838L8.76725 9.46982C7.9415 9.5587 7.22881 10.1296 6.97231 10.9543C6.7265 11.7429 6.9605 12.6063 7.55169 13.1604L8.09281 13.6672L7.93643 14.4294C7.92181 14.4991 7.91112 14.5694 7.90381 14.6392Z" fill={active ? "#3049C5" : "#16325C"} />
    </svg>
)
};

export default SkillsIcon;

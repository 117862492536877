import React from "react";

const ToolsIcon = ({ active }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.12787 2.88086L5.54828 3.65825C5.73063 3.75742 5.79781 3.98456 5.69864 4.16691C5.66345 4.23089 5.61226 4.28208 5.54828 4.31727L4.12787 5.09145C4.06389 5.12664 4.0127 5.17783 3.97751 5.24181L3.20013 6.66222C3.10095 6.84457 2.87382 6.91175 2.69147 6.81258C2.62749 6.77739 2.5763 6.7262 2.54111 6.66222L1.76692 5.24181C1.73173 5.17783 1.68055 5.12664 1.61657 5.09145L0.196158 4.31727C0.0138089 4.21809 -0.0533725 3.99096 0.0458 3.80861C0.0809903 3.74462 0.132176 3.69344 0.196158 3.65825L1.61657 2.88086C1.68055 2.84567 1.73173 2.79449 1.76692 2.7305L2.54431 1.3133C2.64348 1.13095 2.87382 1.06377 3.05617 1.16614C3.11695 1.20133 3.16814 1.25251 3.20333 1.3133L3.98071 2.7305C4.0127 2.79449 4.06389 2.84567 4.12787 2.88086Z" fill={active ? "#3049C5" : "#16325C"} />
      <path d="M14.7011 10.4628L16.1215 11.2401C16.3039 11.3393 16.3711 11.5664 16.2719 11.7488C16.2367 11.8128 16.1855 11.864 16.1215 11.8992L14.7011 12.6765C14.6371 12.7117 14.5859 12.7629 14.5508 12.8269L13.7734 14.2473C13.6742 14.4297 13.4439 14.4968 13.2615 14.3977C13.1975 14.3625 13.1463 14.3113 13.1112 14.2473L12.3402 12.8237C12.305 12.7597 12.2538 12.7085 12.1898 12.6733L10.7694 11.896C10.5871 11.7968 10.5199 11.5664 10.619 11.3841C10.6542 11.3201 10.7054 11.2689 10.7694 11.2337L12.1898 10.4564C12.2538 10.4212 12.305 10.37 12.3402 10.306L13.1176 8.88879C13.2167 8.70644 13.4471 8.63925 13.6294 8.73843C13.6934 8.77362 13.7446 8.8248 13.7798 8.88879L14.5572 10.3092C14.5859 10.3764 14.6371 10.4276 14.7011 10.4628Z" fill={active ? "#3049C5" : "#16325C"} />
      <path d="M8.55282 1.25565L9.48377 1.76431C9.60213 1.83149 9.64692 1.98185 9.58294 2.10022C9.56055 2.1418 9.52536 2.17379 9.48377 2.19939L8.55282 2.70805C8.51124 2.73044 8.47605 2.76563 8.45365 2.80722L7.94499 3.73816C7.87781 3.85653 7.72745 3.90132 7.60909 3.83733C7.5675 3.81494 7.53551 3.77975 7.50991 3.73816L7.00125 2.80722C6.97886 2.76563 6.94367 2.73044 6.90208 2.70805L5.97114 2.19939C5.85277 2.13541 5.80798 1.98505 5.87197 1.86348C5.89436 1.82189 5.92955 1.7867 5.97114 1.76431L6.90208 1.25565C6.94367 1.23326 6.97886 1.19806 7.00125 1.15648L7.50991 0.225534C7.57709 0.107167 7.72745 0.0623792 7.84582 0.126362C7.88741 0.148755 7.9194 0.183946 7.94499 0.225534L8.45365 1.15648C8.47605 1.19806 8.51124 1.23326 8.55282 1.25565Z" fill={active ? "#3049C5" : "#16325C"} />
      <path d="M16.0152 1.68114L14.7003 0.366303C14.214 -0.119963 13.4239 -0.123162 12.9344 0.363103C12.9344 0.363103 12.9344 0.363103 12.9312 0.366303L1.20965 12.0911C0.723384 12.5773 0.720185 13.3675 1.20965 13.857L2.52449 15.1718C3.01075 15.6581 3.80093 15.6613 4.2904 15.1718L16.0152 3.44705C16.5014 2.96078 16.5014 2.1706 16.0152 1.68114ZM11.4436 6.11511L10.2663 4.93784L13.7374 1.4636L14.9147 2.64087L11.4436 6.11511Z" fill={active ? "#3049C5" : "#16325C"} />
    </svg>
  )
};

export default ToolsIcon;

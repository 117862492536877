import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Button} from "../../components/Buttons";
import {Input} from "../../components/Form";
import {registerPassword} from "../../utils/api";
import {
  setToken, setUrlSuffix,
  setUser,
  setUserId as setLoginUserId,
} from "../../utils/localStorageService";
import {useHistory} from "react-router-dom";

const Register = ({ setLogged }) => {
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  useEffect(() => {
    document.title = "Create Your Password";
    const userId = new URLSearchParams(window.location.search).get('userId');
    const email = new URLSearchParams(window.location.search).get('email');
    setUserId(userId);
    setEmail(email);
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleRegisterPassword = async () => {
    if (!email || !userId) {
      alert.error(<AlertError error={{message: 'Email and userId are required'}} message={$.auth_error} />);
      return;
    }
    try {
      const response = await registerPassword({ userId, password });
      const talent = response.talent;
      if (!talent) {
        alert.error(<AlertError message="Talent not found." />);
        return;
      }
      setToken(response);
      setLoginUserId(response.talent.user.id);
      const suffix = response.talent.url_suffix;
      setUrlSuffix(suffix);
      setLogged(true);
      history.push(`/talent/${suffix}`);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center px-24 py-12">
      <div className="rounded-2xl bg-white relative w-full h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col items-center justify-center px-10"
          >
            <h1 className="font-semibold text-2xl mb-4">
              Create a password
            </h1>
            <label className="text-sm text-left w-full mb-1">
              Enter Password
            </label>
            <div className="w-full mb-3">
              <Input
                type="password"
                fontSize="text-sm"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <label
              className="text-sm text-left w-full mb-1"
            >
              Confirm Password
            </label>
            <div className="w-full mb-4">
              <Input
                type="password"
                fontSize="text-sm"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <Button
              className="w-full"
              disabled={!password || !confirmPassword || password !== confirmPassword}
              onClick={handleRegisterPassword}
            >
              Continue
            </Button>
          </div>
          <div
            className="w-1/2 h-full bg-lightest-grey rounded-2xl pt-10 text-center flex flex-col justify-between"
          >
            <div className="px-8 h-1/4">
              <h1 className="text-3xl">
                Be your own boss, boss
              </h1>
              <p className="text-sm mt-2">
                Deliver excellent work that thrills clients by taking on
                projects
                that light you up. Whether you want to moonlight or freelance
                full
                time, you dictate who you work with, how many hours you work,
                and how much you get.
              </p>
            </div>
            <div className="flex-auto w-full flex flex-row text-white text-xl">
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-1/2 bg-link"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-20 bg-link-dark pt-6">RIGHT</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-32 bg-indigo pt-6">SIDE</div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-44 bg-link-dark pt-6">UP</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-52 bg-indigo"></div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React, { useMemo } from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";

const CompanyStages = ({ talentData, setTalentData }) => {
  // const { qualifications } = talentData;

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex gap-2 items-center">
        <div className="text-normal font-bold">Company Stages</div>
        <div className="ml-auto mr-0">
          <EditBtn />
        </div>
      </div>
      <div className="py-2 text-sm flex gap-4">
        {/* todo add company stages */}
      </div>
    </div>
  )
};

export default CompanyStages;

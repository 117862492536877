import React, { useMemo } from "react";
import $ from "strings/talent";
import { Label, Sublabel } from "components/Form";
import {
  Error,
  RatingHelper,
  Qualification
} from "../../components/index";

const HIDDEN_INDUSTRY_IDS = [
  '57ddc7ac-da7e-4eee-946c-0b697e0819e8' // International Talent - not user facing  
];

const Industries = ({
  setFieldValue,
  values,
  errors,
  setFieldError,
  qualifications
}) => {
  const industries = useMemo(() => {
    return qualifications.filter(q => HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 && q.type.toLowerCase() === 'industry');
  }, [qualifications]);

  const onRatingChange = (id, rating) => {
    const talentIndustries = values.industries;
    let newTalentIndustries;
    if (rating === null || rating === 0) {
      newTalentIndustries = talentIndustries;
      delete newTalentIndustries[id];
    } else {
      newTalentIndustries = {...talentIndustries, [id]: parseInt(rating) };
      if (errors['industries']) {
        setFieldError('industries', null)
      }
    }
    setFieldValue('industries', newTalentIndustries);
  };

  return (
    <div className="px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.industries_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.niche_subtitle}</div>
      </div>
      <div className="flex flex-col">
        <div className="mb-2">
          <Label className="font-medium text-darkest-navy text-sm sm:text-base">{$.industries_label}</Label>
          <Sublabel>{$.rate_any_sublabel}</Sublabel>
        </div> 
        {industries && <div className="ratingContainer industryContainer bg-white rounded-xl p-2 sm:px-4 overflow-y-scroll">
          <div className="flex flex-col py-2 gap-2">
            <RatingHelper label={'Industry'}/>
            {industries.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(q =>
              <Qualification
                key={q.id}
                qualification={q}
                rating={values.industries[q.id] || null}
                onRatingChange={onRatingChange}
              />
            )}
          </div>
        </div>}
      </div>
      {errors.industries ? <Error msg={errors.industries} /> : <div style={{height: '22px'}} />}
    </div>
  )
};

export default Industries;

import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import {GoogleLogin} from '@react-oauth/google';
import {oauth} from "utils/adminApi";
import logoSm from "assets/logo_sm.svg";
import {
  setToken,
  setUrlSuffix,
} from "utils/localStorageService";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Button} from "../../components/Buttons";
import {Input} from "../../components/Form";
import {Link, useHistory} from "react-router-dom";

const Register = ({ setLogged }) => {
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    document.title = "Register";
    const userId = new URLSearchParams(window.location.search).get('userId');
    const email = new URLSearchParams(window.location.search).get('email');
    setUserId(userId);
    setEmail(email);
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const responseGoogle = async (response) => {
    try {
      const authResponse = await oauth(response.credential);
      const talent = authResponse.talent;
      if (!talent) {
        alert.error(<AlertError message="Talent not found." />);
        return;
      }
      setToken(authResponse);
      setUserId(authResponse.talent.user.id);
      setUrlSuffix(authResponse.talent.url_suffix);
      setLogged(true);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  const failedGoogle = (response) => {
    console.error(response);
    const e = { message: response.error };
    alert.error(<AlertError error={e} message={$.google_auth_error} />);
  };

  const handleRegister = async () => {
    if (!email || !userId) {
      alert.error(<AlertError error={{message: 'Email and userId are required'}} message={$.auth_error} />);
      return;
    }
    // go to /talent/register-password with email and userId in query params
    history.push(`/talent/register-password?email=${email}&userId=${userId}`);
  };

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center px-24 py-12">
      <div className="rounded-2xl bg-white relative w-full h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col items-center justify-center px-10"
          >
            <h1
              className="font-semibold text-2xl">
              Join the Right Side Up Collective!
            </h1>
            <div className="flex justify-center my-5 w-full">
               <GoogleLogin
                className="w-full"
                onSuccess={responseGoogle}
                onError={failedGoogle}
                context="signin"
                size="large"
              />
            </div>
            <div className="flex items-center w-full mb-4">
              <hr className="flex-auto w-auto border-t text-gray-300"/>
              <span className="flex-none mx-4 text-gray-300 text-sm font-normal">OR</span>
              <hr className="flex-auto w-auto border-t text-gray-300"/>
            </div>
            {/* todo - use form + submit for login flow + register and others */}
            <label
              className="text-sm text-left w-full mb-1">
              Email
            </label>
            <div className="w-full">
              <Input
                disabled
                placeholder={email}
                fontSize="text-sm"
              />
            </div>
            <p className="my-4 text-sm text-left w-full text-kasmir">
              By continuing, you agree to Right Side Up's {" "}
              <Link to="/terms" className="underline">Terms and Conditions</Link>
              {" "}and{" "}
              <Link to="/privacy" className="underline">{$.privacy_link}</Link>.
            </p>
            <Button
              className="w-full"
              onClick={handleRegister}
            >
              Continue
            </Button>
            <p className="mb-4 mt-4 text-sm text-center w-full text-kasmir">
              Already on Right Side Up? {" "}
              <Link
                to="/talent/login"
                className="underline text-link hover:text-link-dark"
              >Log in</Link>
            </p>
          </div>
          <div
            className="w-1/2 h-full bg-dark-navy rounded-2xl text-white pt-10 px-8">
            <h1 className="text-3xl">
              "Out on your own" never felt so supported
            </h1>
            <p className="text-sm mt-1">
              Going out on your own can be scary. Don't worry - we're just one desk over.
            </p>
            <div className="my-14 pl-4 pr-6 py-4 bg-midnight rounded-xl">
              <p>"Right Side Up has been an incredibly valuable resource and partner
                They've connected me with some of my all time favorite clients and supported
                my growth as a full time consultant."</p>
              <div className="mt-6 flex">
                <div className="flex rounded-3xl w-12 bg-indigo mr-2">
                  <div className="m-auto">👩</div>
                </div>
                <div>
                  <p>Alley</p>
                  <p>Performance Marketing</p>
                </div>
              </div>
            </div>
            <div>
              <p className="text-sm text-caded">
                If you've got the marketing chops, we probably got someone you should meet:
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React from "react";

const SpecialtiesIcon = ({ active }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3549_3178)">
        <path d="M13.4996 9.75002C12.8399 9.74774 12.198 9.96367 11.6738 10.3642C11.1496 10.7647 10.7726 11.3273 10.6014 11.9644C10.4303 12.6015 10.4746 13.2773 10.7275 13.8865C10.9803 14.4958 11.4276 15.0044 11.9996 15.333V17.4578C11.9995 17.5648 12.0312 17.6694 12.0906 17.7583C12.1501 17.8473 12.2345 17.9167 12.3334 17.9576C12.4322 17.9986 12.541 18.0093 12.6459 17.9884C12.7508 17.9675 12.8472 17.9159 12.9228 17.8403L13.4996 17.2643L14.0763 17.8403C14.152 17.9159 14.2483 17.9675 14.3533 17.9884C14.4582 18.0093 14.567 17.9986 14.6658 17.9576C14.7647 17.9167 14.8491 17.8473 14.9085 17.7583C14.968 17.6694 14.9996 17.5648 14.9996 17.4578V15.333C15.5716 15.0044 16.0188 14.4958 16.2717 13.8865C16.5246 13.2773 16.5689 12.6015 16.3977 11.9644C16.2266 11.3273 15.8495 10.7647 15.3254 10.3642C14.8012 9.96367 14.1593 9.74774 13.4996 9.75002Z" fill={active ? "#3049C5" : "#16325C"} />
        <path d="M9 12.75C8.99908 11.9598 9.2065 11.1833 9.60135 10.4988C9.9962 9.81433 10.5645 9.24604 11.249 8.85123C11.9336 8.45642 12.7101 8.24904 13.5003 8.25001C14.2905 8.25098 15.0665 8.46026 15.75 8.85675V3.75C15.7488 2.7558 15.3533 1.80267 14.6503 1.09966C13.9473 0.396661 12.9942 0.00119089 12 0L6 0C5.0058 0.00119089 4.05267 0.396661 3.34966 1.09966C2.64666 1.80267 2.25119 2.7558 2.25 3.75V12.75C2.25119 13.7442 2.64666 14.6973 3.34966 15.4003C4.05267 16.1033 5.0058 16.4988 6 16.5H10.5V16.095C10.0283 15.6747 9.65082 15.1594 9.3923 14.5829C9.13379 14.0064 9.0001 13.3818 9 12.75ZM6 3H12C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75C12.75 3.94891 12.671 4.13968 12.5303 4.28033C12.3897 4.42098 12.1989 4.5 12 4.5H6C5.80109 4.5 5.61032 4.42098 5.46967 4.28033C5.32902 4.13968 5.25 3.94891 5.25 3.75C5.25 3.55109 5.32902 3.36032 5.46967 3.21967C5.61032 3.07902 5.80109 3 6 3ZM6 6H12C12.1989 6 12.3897 6.07902 12.5303 6.21967C12.671 6.36032 12.75 6.55109 12.75 6.75C12.75 6.94891 12.671 7.13968 12.5303 7.28033C12.3897 7.42098 12.1989 7.5 12 7.5H6C5.80109 7.5 5.61032 7.42098 5.46967 7.28033C5.32902 7.13968 5.25 6.94891 5.25 6.75C5.25 6.55109 5.32902 6.36032 5.46967 6.21967C5.61032 6.07902 5.80109 6 6 6ZM8.25 10.5H6C5.80109 10.5 5.61032 10.421 5.46967 10.2803C5.32902 10.1397 5.25 9.94891 5.25 9.75C5.25 9.55109 5.32902 9.36032 5.46967 9.21967C5.61032 9.07902 5.80109 9 6 9H8.25C8.44891 9 8.63968 9.07902 8.78033 9.21967C8.92098 9.36032 9 9.55109 9 9.75C9 9.94891 8.92098 10.1397 8.78033 10.2803C8.63968 10.421 8.44891 10.5 8.25 10.5Z" fill={active ? "#3049C5" : "#16325C"} />
      </g>
      <defs>
        <clipPath id="clip0_3549_3178">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default SpecialtiesIcon;

import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import {resetPassword} from "utils/api";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Input} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {useHistory} from "react-router-dom";

const TalentResetPassword = ({ setLogged }) => {
  const [email, setEmail] = useState();
  useEffect(() => {
    document.title = "Reset Password";
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleResetPassword = async () => {
    try {
      const response = await resetPassword({ email });
      alert.success(response.message);
      history.push(`/talent/reset-password-success?email=${email}`);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
   }
  };

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center px-24 py-12">
      <div className="rounded-2xl bg-white relative w-full h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col items-center justify-center px-10"
          >
            <h1
              className="font-semibold text-2xl mb-1"
            >Enter your email address</h1>
            <h2 className="text-md text-center text-kasmir mb-4">
              We will send you an email with a link to reset your password
            </h2>
            <label
              className="text-sm text-left w-full mb-1"
            >
              Email
            </label>
            <div className="w-full mb-4">
              <Input
                type="email"
                fontSize="text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Button
              className="w-full"
              onClick={handleResetPassword}
            >Reset password</Button>
            <p className="mb-4 mt-4 text-sm text-center w-full text-kasmir">
              Having trouble logging in? {" "}
              <a
                href="mailto:talent@rightsideup.co"
                className="underline text-link hover:text-link-dark"
              >Contact support</a>
            </p>
          </div>
          <div
            className="w-1/2 h-full bg-lightest-grey rounded-2xl pt-10 text-center flex flex-col justify-between"
          >
            <div className="px-8 h-1/4">
              <h1 className="text-3xl">
                Be your own boss, boss
              </h1>
              <p className="text-sm mt-2">
                Deliver excellent work that thrills clients by taking on
                projects
                that light you up. Whether you want to moonlight or freelance
                full
                time, you dictate who you work with, how many hours you work,
                and how much you get.
              </p>
            </div>
            <div className="flex-auto w-full flex flex-row text-white text-xl">
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-1/2 bg-link"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-20 bg-link-dark pt-6">RIGHT</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-32 bg-indigo pt-6">SIDE</div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-44 bg-link-dark pt-6">UP</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-52 bg-indigo"></div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentResetPassword;

import React from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";

const AboutMe = ({ talentData, setTalentData }) => {
  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex">
        <div className="text-lg font-bold">About Me</div>
        <div className="ml-auto mr-0">
          <EditBtn />
        </div>
      </div>
      <div className="py-2 text-sm">
        Description 
        {/* TODO where is this? */}
      </div>
    </div>
  )
};

export default AboutMe;

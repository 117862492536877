import React, { useState, useMemo, useEffect } from "react";
import $ from "strings/talent";
import $$ from "strings/talent"
import { Label } from "components/Form";
import { B2DB } from "components/Typography"
import {
  Qualification,
  RatingHelper,
  Pill
} from "../components/index";
import ActionBar from "./ActionBar";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import { remove } from "lodash";

const Skills = ({
  rsuQualifications,
  talentSkills,
  talentData,
  onUpdate,
  isUpdating
}) => {
  const [open, setOpen] = useState('specialty');
  const [displayActionBar, setDisplayActionBar] = useState(false);
  const [talentValues, setTalentValues] = useState(talentSkills);
  const [toUpdate, setToUpdate] = useState([]);
  const [toCreate, setToCreate] = useState([]);

  useEffect(() => {
    if (!toCreate || !toUpdate) setDisplayActionBar(false);
    toUpdate.length || toCreate.length ? setDisplayActionBar(true) : setDisplayActionBar(false)
  }, [toCreate, toUpdate]);

  const specialtiesArray = useMemo(() => {
    if (!talentData) return;
    if (!talentData.specialties?.length) {
      setOpen('other');
      return [];
    } else {
      const arr = talentData.specialties.split('|');
      return arr;
    }
  }, [talentData]);
  
  const [specialtyTalentSkills, specialtyRSUSkills, otherTalentSkills, otherRSUSkills] = useMemo(() => {
    let specialtyTalent = [];
    let specialtyRSU = [];
    let otherTalent = [];
    let otherRSU = [];
    
    const skills = rsuQualifications?.filter(q => q.type.toLowerCase() === 'skill');
    skills.forEach((s) => {
      const isRated = talentSkills.find(ts => ts.qualification_id === s.id);
      if (specialtiesArray.some((q) => s.grouping.includes(q))) {
        isRated ? specialtyTalent.push({...s, talent_rating: isRated.talent_rating}) : specialtyRSU.push(s)
      } else {
        isRated ? otherTalent.push({...s, talent_rating: isRated.talent_rating}) : otherRSU.push(s)
      }
    });
    specialtyTalent.sort((a, b) => b.talent_rating - a.talent_rating);
    specialtyRSU.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    otherTalent.sort((a, b) => b.talent_rating - a.talent_rating)
    otherRSU.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    
    return [specialtyTalent, specialtyRSU, otherTalent, otherRSU];
  }, [specialtiesArray, rsuQualifications])

  const onRatingChange = (id, rating) => {
    let newToUpdate = toUpdate;
    let newToCreate = toCreate;
    let newTalentValues = talentValues;
    let checked = document.getElementById(`checkbox--${id}`)?.checked;
    const existingQualification = talentSkills.find(skill => skill.qualification_id === id);
    
    if (existingQualification) {
      remove(newToUpdate, skill => skill.qualification_id === id);
      newToUpdate.push({
        qualification_id: id,
        talent_qualification_id: existingQualification.talent_qualification_id,
        talent_rating: rating,
        display_on_talent_profile: checked
      });
      setToUpdate([...newToUpdate]);
    } else {
      remove(newToCreate, skill => skill.qualification_id === id);
      if (rating !== 0) {
        newToCreate.push({
          qualification_id: id,
          talent_rating: rating,
          display_on_talent_profile: checked,
        });
        setToCreate([...newToCreate]);
      }
    }
    remove(newTalentValues, skill => skill.qualification_id === id);
    newTalentValues.push({qualification_id: id, talent_rating: rating, display_on_talent_profile: checked});
    setTalentValues([...newTalentValues]);
  };

  const onCheckboxClick = (id) => {
    const value = document.getElementById(`checkbox--${id}`)?.checked;
    let newToUpdate = toUpdate;
    let newTalentValues = talentValues;
    const existingQualification = talentSkills.find(skill => skill.qualification_id === id);
    if (!existingQualification) return;
    remove(newToUpdate, skill => skill.qualification_id === id);
    let newVal = {
      qualification_id: existingQualification.qualification_id,
      qualification_name: existingQualification.qualification_name,
      talent_qualification_id: existingQualification.talent_qualification_id,
      talent_rating: existingQualification.talent_rating,
      display_on_talent_profile: value
    }
    if (existingQualification.display_on_talent_profile !== value) {
      newToUpdate.push(newVal);
      setToUpdate([...newToUpdate]);
    }
    remove(newTalentValues, skill => skill.qualification_id === id);
    newTalentValues.push(newVal);
    setTalentValues([...newTalentValues]);
  }

  const onClickSave = () => {
    const response = onUpdate(toCreate, toUpdate);
    if (response) {
      setToUpdate([])
      setToCreate([])
      setDisplayActionBar(false)
    }
  }

  const onClickCancel = () => {
    setToUpdate([])
    setToCreate([])
    setTalentValues(talentSkills)
    setDisplayActionBar(false)
  }

  return (
    <div className="bg-white rounded-xl px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl font-bold">{$.skills_title}</div>
      </div>
      <div className="flex flex-col">
        <div className="pb-2">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$$.skills_helper}</Label>
        </div>
        {(specialtiesArray?.length > 0) && <div className="ratingContainer bg-lightest-grey rounded-xl mb-2 p-2 sm:px-4">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen('specialty')}>
            <B2DB className="flex flex-1 items-center gap-2">
              {$.specialty_skills_label}
              <Pill value={specialtyTalentSkills.length + specialtyRSUSkills.length} />
            </B2DB>
            {open === 'specialty' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'specialty' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Skill'} showDisplayOnProfile />
              {specialtyTalentSkills.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                  onRatingChange={onRatingChange}
                  disabled={isUpdating}
                  showCheckbox
                  checkboxDisabled={isUpdating || !talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0}
                  onCheckboxClick={() => onCheckboxClick(q.id)}
                  checked={talentValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
                />
              )}
              {specialtyRSUSkills.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                  onRatingChange={onRatingChange}
                  disabled={isUpdating}
                  showCheckbox
                  checkboxDisabled={isUpdating || !talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0}
                  onCheckboxClick={() => onCheckboxClick(q.id)}
                  checked={talentValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
                />
              )}
            </div>
            }
          </div>
        }
        <div className="ratingContainer bg-lightest-grey rounded-xl p-2 sm:px-4">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen('other')}>
            <B2DB className="flex flex-1 items-center gap-2">
              {(specialtiesArray?.length > 0) ? $.other_skills_subheader : $.skills_title}
              <Pill value={otherTalentSkills.length + otherRSUSkills.length} />
            </B2DB>
            {open === 'other' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'other' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Skill'} showDisplayOnProfile />
              {otherTalentSkills.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                  onRatingChange={onRatingChange}
                  disabled={isUpdating}
                  showCheckbox
                  checkboxDisabled={isUpdating || !talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0}
                  onCheckboxClick={() => onCheckboxClick(q.id)}
                  checked={talentValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
                />
              )}
              {otherRSUSkills.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                  onRatingChange={onRatingChange}
                  disabled={isUpdating}
                  showCheckbox
                  checkboxDisabled={isUpdating || !talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating || talentValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0}
                  onCheckboxClick={() => onCheckboxClick(q.id)}
                  checked={talentValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
                />
              )}
            </div>
          }
        </div>
      </div>
      <ActionBar displayActionBar={displayActionBar} isUpdating={isUpdating} onClickCancel={onClickCancel} onClickSave={onClickSave} />
    </div>
  )
};

export default Skills;

import React from "react";
import clsx from "clsx";
import $ from "strings/talent";
import {
  Label,
  Sublabel,
  Checkbox,
  Radio
} from "components/Form";
import { Error } from "../../components/index";

const EMPLOYMENT_STATUS_OPTIONS = [
  {label: $.w2_employee_option, value: 'w2_employee'},
  {label: $.ft_freelancer_option, value: 'fulltime_freelancer'},
  {label: $.pt_freelancer_option, value: 'parttime_freelancer'},
  {label: $.student_option, value: 'student'},
  {label: $.searching_for_w2_option, value: 'searching_for_w2'},
]

const FULLTIME_FREELANCING_OPTIONS = [
  {label: $.yes_option, value: true},
  {label: $.no_option, value: false}
]

const BUSINESS_HOURS_OPTIONS = [
  {label: $.yes_option, value: 'yes'},
  {label: $.depends_on_day_option, value: 'depends_on_day'},
  {label: $.no_option, value: 'no'}
]

const EmploymentStatus = ({
  setFieldValue,
  values,
  errors,
  touched,
  setFieldError
}) => {
  const onCheckboxClick = (fieldName, option, value) => {
    const currentValues = values[fieldName];
    let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
    if (value === true && currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
      if (errors[fieldName]) {
        setFieldError(fieldName, null);
      }
    } else if (value === false && currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
    }
    const newValues = currentValuesArr.join('|')
    setFieldValue(fieldName, newValues);
  }

  const onRadioClick = (name, value) => {
    setFieldValue(name, value);
    if (errors[name]) {
      setFieldError(name, null)
    }
  }

  return (
    <div className="px-8 py-4 grow">
      <div className="py-1 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.employment_status_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.employment_status_subtitle}</div>
      </div>
      <div className="scrollableSurveyContent flex flex-col gap-2 py-4">
        <div className="my-px">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.employment_status_label}</Label>
          <Sublabel>{$.select_all_sublabel}</Sublabel>
          {EMPLOYMENT_STATUS_OPTIONS.map((option) => 
            <Checkbox
              outlined
              key={option.value}
              id={option.value}
              value={values['employmentStatus']?.includes(option.value) ? true : false}
              onChange={(v) => onCheckboxClick('employmentStatus', option.value, v)}
              disabled={
                option.value === 'fulltime_freelancer' && values['employmentStatus']?.includes('parttime_freelancer') ||
                option.value === 'parttime_freelancer' && values['employmentStatus']?.includes('fulltime_freelancer')
              }
            >
              <div className={
                clsx("ml-1 my-1 font-sm font-lato",
                  option.value === 'fulltime_freelancer' && values['employmentStatus']?.includes('parttime_freelancer') ||
                  option.value === 'parttime_freelancer' && values['employmentStatus']?.includes('fulltime_freelancer') ?
                  "text-kasmir" : "text-darkest-navy"
              )}>
                {option.label}
              </div>
            </Checkbox>
          )}
          {(!touched.employmentStatus && errors.employmentStatus) ? <Error msg={errors.employmentStatus} /> : <div style={{height: '22px'}} />}
        </div>
        {(!values['employmentStatus']?.includes('fulltime_freelancer')) &&
          <div className="my-px">
            <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.fulltime_freelancing_label}</Label>
            <Sublabel>{$.fulltime_freelancing_sublabel}</Sublabel>
            {FULLTIME_FREELANCING_OPTIONS.map((option) => 
              <Radio
                outlined
                key={option.value}
                value={option.value}
                name="openToFulltimeFreelance"
                checked={values['openToFulltimeFreelance'] === option.value}
                onChange={() => onRadioClick('openToFulltimeFreelance', option.value)}
              >
                <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
              </Radio>
            )}
            {(!touched.openToFulltimeFreelance && errors.openToFulltimeFreelance) ? <Error msg={errors.openToFulltimeFreelance} /> : <div style={{height: '22px'}} />}
          </div>
        }
        <div className="my-px">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.business_hours_label}</Label>
          <Sublabel>{$.business_hours_sublabel}</Sublabel>
          {BUSINESS_HOURS_OPTIONS.map((option) => 
            <Radio
              outlined
              key={option.value}
              name='businessHoursAvailable'
              value={option.value}
              checked={values['businessHoursAvailable'] === option.value}
              onChange={() => onRadioClick('businessHoursAvailable', option.value)}
            >
              <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
            </Radio>
          )}
          {(!touched.businessHoursAvailable && errors.businessHoursAvailable) ? <Error msg={errors.businessHoursAvailable} /> : <div style={{height: '22px'}} />}
        </div>
      </div>
    </div>
  )
};

export default EmploymentStatus;

import React, { useState, useEffect } from "react";
import $ from "strings/talent";
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useParams,
  useHistory
} from "react-router-dom";
import { withHttp } from "utils/str";
import Logo from "components/Logo";
import Section from "components/Section";
import userImg from "assets/user.svg";
import ProfilePage from "./Profile";
import SettingsPage from "./Settings";
import AssessmentsPage from "./Assessments";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import {clearToken} from "../../utils/localStorageService";
import {getTalentData} from 'utils/api';

const RESOURCES_URL = 'https://learn.rightsideup.com/contractor-resources/?utm_medium=product&utm_source=direct&utm_campaign=talent_profile&utm_content=talent&utm_term=talent_profile'
const TIMESHEETS_URL = 'https://app.clockify.me/tracker'

const Navbar = () => {
  const { id } = useParams();
  const [drawerOpen, setDrawerOpen] = useState();
  const history = useHistory();

  return (
    <div className="bg-white border-b border-geyser flex h-14 items-center">
      <div className="px-2 flex items-center">
        <div className="w-10 flex items-center justify-start flex-shrink-0">
          <Logo />
        </div>
      </div>
      <div className="flex-1 px-5 min-w-0 items-center">
        <div className="text-base text-nav-blue font-medium flex-1 flex gap-4 mx-0 my-auto">
          <NavLink to={`/talent/${id}/profile`}>
            {$.profile_navlink}
          </NavLink>
          <NavLink to={`/talent/${id}/niche`}>
            {$.niche_navlink}
          </NavLink>
          <NavLink to={{pathname: withHttp(RESOURCES_URL)}} target="_blank">
            {$.resource_navlink}
          </NavLink>
          <NavLink to={{pathname: withHttp(TIMESHEETS_URL)}} target="_blank">
            {$.timesheets_navlink}
          </NavLink>
        </div>
      </div>
      <div className="mr-4 ml-auto flex items-center" onClick={() => setDrawerOpen(!drawerOpen)}>
        <img
          src={userImg}
          className="rounded-full w-10 -ml-2 mr-1"
          // alt={fullname}
        />
        {drawerOpen ? <UilUp size="20" /> : <UilDown size="20" />}
        {drawerOpen ?
          <div className="flex flex-col absolute z-10 top-14 right-2 p-2 bg-white border-2 border-link-water rounded-md text-sm text-nav-blue cursor-pointer">
            <NavLink className="p-1 rounded-md hover:bg-link-water" to={`/talent/${id}/settings`}>
              {$.settings_navlink}
            </NavLink>
            <NavLink className="p-1 rounded-md hover:bg-link-water" to={`/talent/${id}/settings`}>
              {$.support_navlink}
            </NavLink>
            <NavLink
              className="p-1 rounded-md hover:bg-link-water"
              onClick={() => {
                clearToken();
                history.push('/talent/login');
              }}
              to={`/talent/login`}
            >
              {$.logout_navlink}
            </NavLink>
          </div> :
          <></>
        }
      </div>
    </div>
  )
}

const Page = () => {
  const { id } = useParams();
  const [talentData, setTalentData] = useState();

  const fetchTalentData = async (id) => {
    try {
      const response = await getTalentData(id);
      setTalentData(response);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (!id) return;
    fetchTalentData(id).then();
  }, [id])

  return (
    <Section
      id="talent_tech_page"
      className="font-lato bg-link-water min-h-full flex flex-col"
    >
      <div className="flex-1 justify-center">
        <Navbar />
        <Switch>
          <Route exact path="/talent/:id">
            <ProfilePage talentData={talentData} setTalentData={setTalentData} talent={id} />
          </Route>
          <Route exact path="/talent/:id/profile">
            <ProfilePage talentData={talentData} setTalentData={setTalentData} talent={id} />
          </Route>
          <Route exact path="/talent/:id/settings">
            <SettingsPage talentData={talentData} setTalentData={setTalentData} />
          </Route>
          <Route exact path="/talent/:id/niche">
            <AssessmentsPage talentData={talentData} setTalentData={setTalentData} talent={id} />
          </Route>
          <Redirect from="/talent/:id" to="/talent/:id/profile" />
        </Switch>
      </div>
    </Section>
  );
};

export default Page;

import React from "react";
import { Button } from "components/Buttons";
import GenericConfirmationImage from "../../assets/confirmation_generic.png";
import { Link } from "react-router-dom";
import $ from "strings/talent";

const Confirmation = () => {
  return (
    <div className="confirmation bg-alice-blue font-lato px-8 py-4 w-full h-full flex flex-col justify-center items-center text-center">
      <div className="w-full sm:w-1/2 items-center justify-center py-2">
        <img className="m-auto" alt="RSU icon" src={GenericConfirmationImage} />
      </div>
      <div className="w-full sm:w-1/2 gap-2 items-center justify-center">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">
          {$.success_title}
        </div>
        <div className="text-midnight text-base sm:text-lg font-normal py-2">
          {$.success_subtitle}
        </div>
        <div className="my-2">
          
            <Link
              to={{ pathname: $.talent_library_url }}
              target="_blank"
            >
              <Button className="animatedBtn bg-electric-indigo text-sm sm:text-base rounded gap-1">
                {$.talent_library_btn}
              </Button>
            </Link>
        </div>
      </div>
    </div>
  ) 
};

export default Confirmation;

import React from "react";
import clsx from "clsx";
import RatingInput from "./RatingInput";
import { Checkbox } from "components/Form";


const Qualification = ({ qualification, rating, onRatingChange, disabled, onCheckboxClick, showCheckbox, checkboxDisabled, checked }) => {
  const { name, id } = qualification;

  return (
    <div className={clsx("flex w-full p-2 text-sm font-medium space-between items-center rounded-xl", showCheckbox ? "bg-white" : "bg-lightest-grey")}>
      <div className="flex-1">{name}</div>
      <div className={clsx(showCheckbox ? "flex-1" : "mr-0 ml-auto")}>
        <RatingInput
          value={rating}
          onChange={(v) => onRatingChange(id, v, qualification)}
          disabled={disabled}
        />
      </div>
      {showCheckbox && <div className="mr-0 ml-auto">
        <Checkbox
          id={`checkbox--${id}`}
          outlined
          disabled={checkboxDisabled}
          value={checked}
          onChange={(v) => onCheckboxClick(v)}
        />
      </div>}
    </div>
  )
};

export default Qualification;

import React from "react";

const TravelDistanceIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2693_8612)">
        <path d="M14.9021 7.87497C13.1963 7.87673 11.8098 9.27419 11.8115 10.9905C11.8122 11.9281 12.2795 12.9969 13.2002 14.1676C13.8573 15.0033 14.5084 15.584 14.5358 15.6083C14.7494 15.798 15.071 15.7967 15.2832 15.6076C15.3106 15.5833 15.9607 15.0011 16.6163 14.1644C17.3197 13.2664 17.999 12.1209 17.999 10.9845C17.9976 9.27152 16.6215 7.87497 14.9021 7.87497ZM14.0615 10.9694C14.0608 10.5034 14.4379 10.1257 14.9042 10.125C15.6549 10.125 16.0306 11.0363 15.5022 11.5646C14.9764 12.0916 14.0615 11.7246 14.0615 10.9694Z" fill="#0E1539"/>
        <path d="M2.72089 7.73297C2.93405 7.92218 3.25485 7.9225 3.46832 7.73297C3.49574 7.70871 4.14648 7.12723 4.80285 6.29121C5.72218 5.11945 6.18835 4.05035 6.18835 3.11238C6.18835 1.39641 4.80039 0 3.0946 0C1.38882 0 0.000854492 1.39641 0.000854492 3.11238C0.000854492 4.05035 0.467026 5.11945 1.38636 6.29121C2.04273 7.12723 2.69347 7.70871 2.72089 7.73297ZM3.0946 2.25C3.55972 2.25 3.93835 2.62863 3.93835 3.09375C3.93835 3.55887 3.55972 3.9375 3.0946 3.9375C2.62949 3.9375 2.25085 3.55887 2.25085 3.09375C2.25085 2.62863 2.62949 2.25 3.0946 2.25Z" fill="#0E1539"/>
        <path d="M15.7547 17.1555C15.7547 17.9261 14.7989 18.2948 14.2827 17.7187H2.53209C1.1364 17.7187 0.000854492 16.5831 0.000854492 15.1874C0.000854492 13.7918 1.1364 12.6562 2.53209 12.6562H8.17465C8.94035 12.6562 9.56332 12.0332 9.56332 11.2675C9.56332 10.5018 8.94035 9.87887 8.17465 9.87887H3.72354C3.55373 10.0663 3.32771 10.1601 3.09459 10.1601C2.34661 10.1601 1.96882 9.24895 2.49729 8.72048C2.8447 8.37229 3.4091 8.39782 3.72318 8.75388H8.17465C9.56086 8.75388 10.6883 9.88134 10.6883 11.2675C10.6883 12.6537 9.56086 13.7812 8.17465 13.7812H2.53209C1.75655 13.7812 1.12585 14.4119 1.12585 15.1874C1.12585 15.963 1.75655 16.5937 2.53209 16.5937C15.1889 16.5937 14.2641 16.6102 14.314 16.5603C14.8411 16.032 15.7541 16.403 15.7547 17.1555Z" fill="#0E1539"/>
      </g>
      <defs>
        <clipPath id="clip0_2693_8612">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default TravelDistanceIcon;

import React from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";

const Projects = ({ talentData, setTalentData }) => {
  return (
    <div className="bg-white rounded-lg p-4">
      <div className="flex">
        <div className="text-normal">Projects</div>
        <div className="ml-auto mr-0">
          <EditBtn />
        </div>
      </div>
      <div className="py-2 text-sm">
        projects
      </div>
    </div>
  )
};

export default Projects;

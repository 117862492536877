import React from "react";

const FullTimeIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2693_8626)">
        <path d="M17.3847 10C17.2215 10 17.065 9.93128 16.9496 9.80895C16.8342 9.68663 16.7694 9.52072 16.7694 9.34772C16.7695 7.75656 16.3245 6.20111 15.4907 4.87807C14.6568 3.55504 13.4716 2.52386 12.0849 1.91493C10.6982 1.30601 9.17237 1.14669 7.70027 1.45714C6.22817 1.76759 4.87598 2.53384 3.8147 3.65901C3.5603 3.92908 3.324 4.21766 3.10752 4.52263C3.00961 4.66102 2.86385 4.75251 2.70231 4.77698C2.54076 4.80145 2.37667 4.75688 2.24612 4.65308C2.11557 4.54928 2.02926 4.39476 2.00619 4.22351C1.98311 4.05225 2.02515 3.87829 2.12306 3.73989C2.37485 3.38721 2.64895 3.05306 2.94345 2.73974C4.17659 1.43192 5.74787 0.541126 7.45859 0.180013C9.1693 -0.181101 10.9426 0.0036845 12.5543 0.711C14.1659 1.41832 15.5435 2.61639 16.5128 4.15372C17.4822 5.69105 17.9997 7.49858 18 9.34772C18 9.52072 17.9352 9.68663 17.8198 9.80895C17.7044 9.93128 17.5479 10 17.3847 10Z" fill="#0E1539"/>
        <path d="M8.82968 18C7.67009 18.0002 6.52182 17.7586 5.45045 17.2889C4.37909 16.8192 3.40563 16.1306 2.58567 15.2626C1.76572 14.3945 1.11534 13.3639 0.67168 12.2297C0.228024 11.0955 -0.000215645 9.87981 1.52883e-07 8.65217C1.52883e-07 8.47921 0.0649025 8.31332 0.180429 8.19102C0.295956 8.06871 0.452644 8 0.616024 8C0.779404 8 0.936092 8.06871 1.05162 8.19102C1.16715 8.31332 1.23205 8.47921 1.23205 8.65217C1.23196 10.2431 1.67751 11.7983 2.51234 13.1211C3.34718 14.4439 4.53379 15.475 5.92213 16.0838C7.31047 16.6926 8.83817 16.8519 10.312 16.5415C11.7859 16.2311 13.1397 15.465 14.2022 14.34C14.4568 14.0704 14.6931 13.7821 14.9094 13.4774C15.0107 13.3488 15.1547 13.2664 15.3117 13.247C15.4687 13.2276 15.6268 13.2728 15.7533 13.3732C15.8797 13.4736 15.965 13.6216 15.9913 13.7866C16.0177 13.9516 15.9833 14.1211 15.8951 14.26C15.6433 14.6132 15.3689 14.9476 15.0737 15.2608C14.2556 16.1317 13.2825 16.8222 12.2108 17.2924C11.139 17.7625 9.98981 18.003 8.82968 18Z" fill="#0E1539"/>
        <path d="M16.307 17C16.1232 17 15.9469 16.927 15.817 16.797C15.687 16.667 15.614 16.4907 15.614 16.3068V14.3863H13.693C13.5092 14.3863 13.3329 14.3133 13.203 14.1833C13.073 14.0533 13 13.877 13 13.6932C13 13.5093 13.073 13.333 13.203 13.203C13.3329 13.073 13.5092 13 13.693 13H16.307C16.4908 13 16.6671 13.073 16.797 13.203C16.927 13.333 17 13.5093 17 13.6932V16.3068C17 16.4907 16.927 16.667 16.797 16.797C16.6671 16.927 16.4908 17 16.307 17Z" fill="#0E1539"/>
        <path d="M4.30684 5H1.69316C1.50932 5 1.33301 4.92697 1.20302 4.79698C1.07303 4.66698 1 4.49068 1 4.30684V1.69316C1 1.50932 1.07303 1.33301 1.20302 1.20302C1.33301 1.07303 1.50932 1 1.69316 1C1.877 1 2.05331 1.07303 2.1833 1.20302C2.31329 1.33301 2.38632 1.50932 2.38632 1.69316V3.61368H4.30684C4.49068 3.61368 4.66698 3.68671 4.79698 3.8167C4.92697 3.94669 5 4.123 5 4.30684C5 4.49068 4.92697 4.66698 4.79698 4.79698C4.66698 4.92697 4.49068 5 4.30684 5Z" fill="#0E1539"/>
        <path d="M9 3C7.81331 3 6.65328 3.35189 5.66658 4.01118C4.67989 4.67047 3.91085 5.60754 3.45673 6.7039C3.0026 7.80026 2.88378 9.00665 3.11529 10.1705C3.3468 11.3344 3.91825 12.4035 4.75736 13.2426C5.59648 14.0818 6.66557 14.6532 7.82946 14.8847C8.99335 15.1162 10.1997 14.9974 11.2961 14.5433C12.3925 14.0891 13.3295 13.3201 13.9888 12.3334C14.6481 11.3467 15 10.1867 15 9C14.9981 7.40928 14.3654 5.88425 13.2406 4.75944C12.1158 3.63463 10.5907 3.00188 9 3ZM11.2222 9.66667H9C8.82319 9.66667 8.65362 9.59643 8.5286 9.4714C8.40357 9.34638 8.33334 9.17681 8.33334 9V6.33333C8.33334 6.15652 8.40357 5.98695 8.5286 5.86193C8.65362 5.7369 8.82319 5.66667 9 5.66667C9.17681 5.66667 9.34638 5.7369 9.47141 5.86193C9.59643 5.98695 9.66667 6.15652 9.66667 6.33333V8.33333H11.2222C11.399 8.33333 11.5686 8.40357 11.6936 8.52859C11.8187 8.65362 11.8889 8.82319 11.8889 9C11.8889 9.17681 11.8187 9.34638 11.6936 9.4714C11.5686 9.59643 11.399 9.66667 11.2222 9.66667Z" fill="#0E1539"/>
      </g>
      <defs>
        <clipPath id="clip0_2693_8626">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default FullTimeIcon;
